
/*** carousel ***/
.d-carousel {
    position: relative;
    display: flex;
    align-items: center;
}
.nft_pic {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    margin: 0 10px 10px;
    transition: .7s;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 1s;
    }
    &:hover {
      img {
        transition: 3s;
        transform: scale(1.2);
      }
    }
}
.nft_pic_info {
    position: absolute;
    background: rgba(0, 0, 0, .2);
    width: 100%;
    height: 100%;
    padding: 40px;
    z-index: 1;
    font-size: 28px;
    color: #ffffff !important;
}
.nft_pic_title {
    display: block;
    font-weight: bold;
    color: #ffffff !important;
}
.nft_pic_by {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff !important;
}
.nft-big{
  width: 100%;
    .slick-prev{
        left: -25px;
        background: #fff !important;
        border-radius: 60px;
        display: block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        z-index: 1;
        transition: all 0.3s ease;
        &::before {
          content: "\f053" !important;
          font-size: .9rem;
          font-family: 'FontAwesome' !important;
          font-style: normal;
          font-weight: normal;
          color: #000;
          position: relative;
          left: 10px;
          line-height: 60px;
      }
    }
    .slick-next{
      right: -25px;
      background: #fff !important;
      border-radius: 60px;
      display: block;
      width: 60px;
      height: 60px;
      line-height: 60px;
      transition: all 0.3s ease;
      &::before {
        content: "\f054" !important;
        font-size: .9rem;
        font-family: 'FontAwesome' !important;
        font-style: normal;
        font-weight: normal;
        color: #000;
        position: relative;
        right: 10px;
        line-height: 60px;
      }
    }
    &:hover{
      .slick-prev, .slick-next{
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
    .blockquote{
      font-size: .98rem;
      text-align: left;
      margin: 15px 30px 30px;
      display: flex;
      .fa {
        font-size: 1.3rem;
        display: inline-block;
        margin: 5px 15px 0 0;
        color: #fff;
        height: max-content;
      }
      p{
        
        line-height: 1.8;
        display: inline-block;
      }
      .bytesti {
        font-size: .9rem;
        font-weight: 400;
      }
    }
    .slick-dots {
      bottom: -40px;
      li.slick-active button::before {
        opacity: .75;
        color: #fff;
      }
      li button::before {
        color: #fff;
      }
    }
}



.de-flex {
  display: flex;
  justify-content: space-between;
}
.de-flex > .de-flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*** footer ***/
footer{
  padding: 80px 0 0 0;
  &.footer-light {
      background: #ffffff !important;
      border-top: solid 1px #eeeeee;
      color: #595d69;
      a {
        color: #595d69;
        font-weight: 400;
        text-decoration: none !important;
    }
    #form_subscribe{
       input[type="text"] {
        width: 80%;
        float: left;
        border-radius: 5px 0 0 5px;
        background: rgba(255, 255, 255, .7);
        border: solid 1px #333333;
        border-right-color: rgb(51, 51, 51);
        border-right-style: solid;
        border-right-width: 1px;
        border-right: none;
        padding: 6px 12px;
        box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
      }
      #btn-subscribe i {
        text-align: center;
        font-size: 28px;
        float: left;
        width: 20%;
        background: transparent;
        color: #ffffff;
        display: table-cell;
        padding: 5px 0 5px 0;
        border-radius: 0 5px 5px 0;
        -moz-border-radius: 0 5px 5px 0;
        -webkit-border-radius: 0 5px 5px 0;
      }
    }
    .subfooter {
      margin-top: 40px;
      padding: 20px 0 20px 0;
      border-top: solid 1px #eeeeee;
      span{
        cursor: pointer;
        img{
          margin-right: 30px;
          &.f-logo.d-1{
            display: inline-block;
          }
          &.f-logo.d-3{
            display: none;
          }
        }
        &.copy{
          cursor: default;
          margin: 0;
        }
      }
      .social-icons {
        display: inline-block;
        span {
          color: #595d69;
          i {
            text-shadow: none;
            color: #fff;
            background: #000;
            padding: 12px 10px 8px 10px;
            width: 34px;
            height: 34px;
            text-align: center;
            font-size: 16px;
            border-radius: 5px;
            margin: 0 5px 0 5px;
            transition: .3s;
          }
          &:hover i{
            color: #000;
            background: #fff;
            transition: .3s;
          }
        }
      }
    }
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 30px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        margin: 5px 0;
      }
    }
    h5 {
      margin-bottom: 20px;
    }
  }
}

#scroll-to-top {
    cursor: pointer;
    width: max-content;
    height: max-content;
    position: fixed;
    right: 10px;
    z-index: 999;
    &.init{
      bottom: -60px;
      transition: all 0.3s ease;
    }
    &.show{
      bottom: 15px;
      transition: all 0.3s ease;
    }
    div{
      font-size: .8rem;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 30px;
      background: transparent;
      transition: all 0.3s ease;
      &:hover{
        transform: scale(1.1);
        transition: all 0.3s ease;
      }
      i{
        font-style: normal;
        &:before{
        font-family: "FontAwesome";
        font-size: 18px;
        content: "\f106";
        color: #fff !important;
        position: relative;
        }
      }
    }
}
